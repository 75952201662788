import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BlogOverview from "../../../components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../components/blog/layout.de";
import AppImage from "../../../components/blog/appimage/appImage";
export const query = graphql`
  {
    mdx(slug: { eq: "kalorienrechner-lebensmittel-favoriten/index.de" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_fourth {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_fourth_alt
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Sich konsequent gut zu ernähren und das Gegessene sorgfältig aufzuzeichnen ist zeitaufwändig und erfordert einiges an Disziplin.
  Deshalb ist es umso wichtiger eine App an deiner Seite zu haben, die dir die Arbeit abnimmt und Abläufe möglichst effizient und automatisch umsetzt. Mit ShapeGanic hast du
  jetzt die Möglichkeit mit dem Markieren von Lebensmitteln als Favoriten noch einfacher und schneller zu tracken, um so mehr Zeit für das Wesentliche im Leben zu haben.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Deine Favoriten auf einen Blick`}</h2>
    <p>
  Wie in der folgenden Abbildung zu sehen ist, werden deine Favoriten übersichtlich in einer Liste dargestellt. Um aus einem Favoriten einen Tracking-Eintrag zu erstellen, musst du einfach auf
  diesen Tippen, die zugeführte Menge eintragen oder einfach nur bestätigen, wenn du dieses Lebensmittel bereits zum Tracken verwendet hast. Möchtest du einen Favoriten wieder löschen musst du
  den entsprechenden Eintrag in der Liste lange gedrückt halten und die Option zum Löschen auswählen.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <h2>{`Lebensmittel als Favoriten markieren`}</h2>
    <p>
  Um ein Lebensmittel als Favorit zu markieren, tippst du auf den entsprechenden Eintrag, um die unten dargestellte Ansicht zu öffnen. Klickst du jetzt auf das Herz-Symbol oben rechts, wird
  das Nahrungsmittel in deine Favoriten-Liste aufgenommen. Nach einem erneuten Tippen wird der Eintrag wieder entfernt.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <h2>{`Favoriten schnell finden`}</h2>
    <p>
  Die untere Abbildung zeigt die Favoriten-Ergebnisse bei einer Volltextsuche, welche nach jedem hinzugefügten Buchstaben
  aktualisiert wird. Die Suchmaske findest du oben, wenn du den Reiter "Mahlzeit" bzw. "Meal" auswählst. Ebenfalls lassen sich
  deine Suchergebnisse nach Kalorien-, Protein-, Kohlenhydrat- und Fettgehalt sortieren, was ebenfalls in folgender Abbildung
  zu sehen ist.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <h2>{`Übersicht verwendeter Lebensmittel`}</h2>
    <p>
  Ein weiteres spezielles Feature ist auf der folgenden Darstellung zu sehen. Hier werden aktuell verwendete Lebensmittel
  aufgeführt. Klickst du auf eines davon, gelangst du sofort zu einer Eingabemaske, bei der die zuletzt verwendete Mengenangabe
  aufgeführt wird. Du musst nur noch bestätigen und der Mahlzeiteneintrag ist erledigt. Solltest du eine Portionsgröße hinterlegt
  haben, so gelangst du alternativ zu einer Ansicht, bei der du die Anzahl der Portionen eingeben kannst.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_fourth)} imageAlt={props.data.mdx.frontmatter.image_fourth_alt} mdxType="AppImage" />
    <p>
  Zu erreichen ist diese Funktion über den Reiter "Mahlzeit" bzw. "Meal" neben der Suchmaske durch einen Klick auf die Option "Verlauf". Um diese Option
  zu sehen musst du unter Umständen auf das Overflow-Menü klicken, welches durch drei Punkte symbolisiert ist. Diese Option
  siehst du nur, wenn es bereits Lebensmittel gibt, welche du aktuell in Tracking-Einträgen verwendest.
    </p>
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      